import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions-page">
      <h1>Terms and Conditions of Use</h1>
      <p>
        Welcome to learnthai.games! By accessing or using our website, you agree to be bound by these terms and conditions of use (the "Terms") and our privacy policy, which is incorporated herein by reference.
      </p>
      <h2>Use of the Website</h2>
      <p>
        This website is provided for your personal, non-commercial use. You may not use this website for any unlawful or prohibited purpose. We reserve the right to modify, suspend, or discontinue the website or any part thereof at any time without notice.
      </p>
      <h2>User Content</h2>
      <p>
        You are solely responsible for any content you upload, post, or otherwise transmit to or through the website. You grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, publish, and distribute such content in connection with operating and improving the website.
      </p>
      <h2>Third-Party Websites and Services</h2>
      <p>
        The website may contain links to third-party websites or services. We are not responsible for the availability, content, or privacy practices of those websites or services. Your use of third-party websites or services is at your own risk.
      </p>
      <h2>Intellectual Property</h2>
      <p>
        The website, its content, and all related intellectual property rights are owned by learnthai.games or its licensors. You may not modify, copy, distribute, transmit, display, reproduce, or create derivative works from the website or its content without our prior written consent.
      </p>
      <h2>Termination</h2>
      <p>
        We reserve the right to terminate your access to the website at any time, for any reason, without notice. You may also delete your account at any time. Upon termination, all provisions of these Terms that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, and limitation of liability.
      </p>
      <h2>Disclaimer of Warranties</h2>
      <p>
        The website is provided "as is" and "as available," without warranty of any kind, either express or implied. We do not warrant that the website will be error-free or uninterrupted.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        In no event shall learnthai.games or its affiliates be liable for any indirect, special, incidental, or consequential damages arising out of or related to your use of the website.
      </p>
      <h2>Governing Law and Jurisdiction</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of the local jurisdiction, without giving effect to any choice or conflict of law provision. Any legal suit, action, or proceeding arising out of or related to these Terms or the website shall be instituted exclusively in the courts of the local jurisdiction.
      </p>
      <h2>Changes to These Terms</h2>
      <p>
        We may modify these Terms at any time. Your continued use of the website after any such changes constitutes your acceptance of the new Terms.
      </p>
      <p>
        If you have any questions or concerns about these Terms, please  at <a href="/contact">contact us</a>.
      </p>
    </div>
  );
};

export default TermsAndConditions;