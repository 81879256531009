import React from 'react';

const GameOver = ({ score, onPlayAgain }) => {
  return (
    <div className="quiz-container">
      <div className="game-over">
        Game Over! Your score: {score}
      </div>
      <button onClick={onPlayAgain} className="start-button">Play Again</button>
    </div>
  );
};

export default GameOver;