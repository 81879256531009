import React from 'react';

const About = () => {
  return (
    <div className="about-page">
      <h1>About</h1>
      <p>
        This app was made by <a href="https://www.linkedin.com/in/jholmes87/" target="_blank" rel="noopener noreferrer">Jonathan Holmes</a>, a Thai language student and web developer. He has lived in Thailand since 2015.
      </p>
    </div>
  );
};

export default About;