import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <h1>Privacy Policy</h1>
      <p>
        At learnthai.games, we are committed to protecting the privacy and security of our users. This privacy policy explains how we collect, use, and safeguard the information you provide when you use our website.
      </p>
      <h2>Information We Collect</h2>
      <p>
        We do not collect any personal user data on our website. We only collect non-personal information, such as the pages you visit, the time and date of your visit, and the type of device and browser you use to access the site.
      </p>
      <p>
        We use Google Analytics, a web analytics service provided by Google, to collect and analyze this non-personal information. Google Analytics uses cookies to help us understand how users interact with our website, so we can improve the user experience.
      </p>
      <p>
        We also use Google Adsense, an advertising service provided by Google, to display ads on our website. Google Adsense may collect certain non-personal information about your browsing behavior to provide you with more relevant and targeted ads.
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        The non-personal information we collect is used to:
      </p>
      <ul>
        <li>Analyze and improve the performance and user experience of our website.</li>
        <li>Provide you with relevant and targeted advertisements through Google Adsense.</li>
      </ul>
      <p>
        We do not share, sell, or rent your non-personal information to any third parties, except as necessary to provide the services and features of our website.
      </p>
      <h2>Your Choices</h2>
      <p>
        You can choose to disable cookies in your browser settings, which will prevent Google Analytics and Google Adsense from collecting non-personal information about your browsing behavior. However, this may affect the functionality and user experience of our website.
      </p>
      <p>
        If you have any questions or concerns about our privacy practices, please <a href="/contact">contact us</a>.
      </p>
      <p>
        This privacy policy is effective as of August 8th 2024 and may be updated periodically to reflect changes in our practices or applicable laws.
      </p>
    </div>
  );
};

export default PrivacyPolicy;