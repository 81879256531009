import React from 'react';
import '../App.css'; // Import the stylesheet

const StartScreen = ({ onStart }) => {
  return (
    <div className="start-screen">
      <div className="header-container">
        <h1>Free Thai learning games</h1>
        <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Logo" className="logo" />
      </div>
      <h2>Practice Thai vocabulary</h2>
      <button className="start-button" onClick={() => onStart('thai-to-english')}>Thai ➡️ English quiz</button>
      <button className="start-button" onClick={() => onStart('english-to-thai')}>English ➡️ Thai quiz</button>
      <h2>Practice Thai consonant classes</h2>
      <button className="start-button" onClick={() => onStart('thai-consonant-class')}>Consonant classes quiz</button>
      <h2>Practice typing Thai</h2>
      <button className="start-button" onClick={() => onStart('speed-typing')}>Speed typing</button>
    </div>
  );
};

export default StartScreen;