import React, { useState, useEffect, useRef, useCallback } from 'react';
import AnswerCard from './AnswerCard';
import GameOver from './GameOver';
import '../App.css'; // Import the stylesheet

const consonantClasses = {
  mid: ['ก', 'จ', 'ฎ', 'ฏ', 'ด', 'ต', 'บ', 'ป', 'อ'],
  high: ['ข', 'ฃ', 'ฉ', 'ฐ', 'ถ', 'ผ', 'ฝ', 'ศ', 'ษ', 'ส', 'ห'],
  low: ['ค', 'ฅ', 'ฆ', 'ง', 'ช', 'ซ', 'ฌ', 'ญ', 'ฑ', 'ฒ', 'ณ', 'ท', 'ธ', 'น', 'พ', 'ฟ', 'ภ', 'ม', 'ย', 'ร', 'ล', 'ว', 'ฬ', 'ฮ']
};

const getRandomConsonant = (remainingConsonants) => {
  const randomIndex = Math.floor(Math.random() * remainingConsonants.length);
  return remainingConsonants[randomIndex];
};

const getConsonantClass = (consonant) => {
  if (consonantClasses.mid.includes(consonant)) return 'mid';
  if (consonantClasses.high.includes(consonant)) return 'high';
  if (consonantClasses.low.includes(consonant)) return 'low';
  return null;
};

const ConsonantsQuiz = ({ onPlayAgain }) => {
  const [remainingConsonants, setRemainingConsonants] = useState([...consonantClasses.mid, ...consonantClasses.high, ...consonantClasses.low]);
  const [currentConsonant, setCurrentConsonant] = useState('');
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [highlightedChoice, setHighlightedChoice] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [questionsAnswered, setQuestionsAnswered] = useState(0); // Track the number of questions answered

  const rightSoundRef = useRef(null);
  const wrongSoundRef = useRef(null);
  const gameOverSoundRef = useRef(null);

  const setNewConsonant = useCallback(() => {
    if (remainingConsonants.length === 0 || currentConsonant) {
      return;
    }
    const newConsonant = getRandomConsonant(remainingConsonants);
    console.log('Setting new consonant:', newConsonant);
    setCurrentConsonant(newConsonant);
    setHighlightedChoice(null);
    setRemainingConsonants(prev => prev.filter(consonant => consonant !== newConsonant));
  }, [remainingConsonants, currentConsonant]);

  const handleAnswerClick = (choice) => {
    const correctAnswer = getConsonantClass(currentConsonant);
    if (choice === correctAnswer) {
      setScore(prevScore => prevScore + 1);
      setFeedback('Correct!');
      if (rightSoundRef.current) {
        rightSoundRef.current.play();
      }
    } else {
      setScore(prevScore => prevScore - 1);
      setFeedback(`Incorrect! '${currentConsonant}' = '${correctAnswer}'`);
      if (wrongSoundRef.current) {
        wrongSoundRef.current.play();
      }
    }
    setHighlightedChoice(choice === correctAnswer ? null : correctAnswer);
    
    setQuestionsAnswered(prev => {
      const newCount = prev + 1;
      console.log('Questions answered:', newCount);
      if (newCount === 44) {
        console.log('Setting game over');
        setGameOver(true);
      } else {
        setCurrentConsonant(''); // Clear current consonant to trigger new one
      }
      return newCount;
    });
  
    setTimeout(() => {
      setFeedback('');
      if (!gameOver) {
        setNewConsonant();
      }
    }, 5000); // Show feedback for 5 seconds
  };

  useEffect(() => {
    if (remainingConsonants.length > 0 && !currentConsonant) {
      console.log('Setting new consonant, remaining:', remainingConsonants.length);
      setNewConsonant();
    }
  }, [setNewConsonant, remainingConsonants.length, currentConsonant]);

  useEffect(() => {
    if (gameOver) {
      if (gameOverSoundRef.current) {
        gameOverSoundRef.current.play();
      }
    }
  }, [gameOver]);

  const handlePlayAgain = () => {
    window.location.reload();
  };

  if (gameOver) {
    return (
      <>
        <GameOver score={score} onPlayAgain={handlePlayAgain} />
        <audio ref={gameOverSoundRef} src="/gameover.mp3"></audio>
      </>
    );
  }

  const feedbackClass = feedback.includes('Correct') ? 'feedback correct' : 'feedback incorrect';

  return (
    <div className="quiz-container">
      <h1>Thai Consonants Class Quiz</h1>
      <div className="score-timer">
        <div className="score">Score: {score}</div>
      </div>
      <div className="question-word">
        {currentConsonant}
      </div>
      <div className="feedback">
        {feedback && (
          <div className={feedbackClass}>
            {feedback}
          </div>
        )}
      </div>
      <div className="choices">
        {['low', 'mid', 'high'].map((choice, index) => (
          <AnswerCard
            key={index}
            choice={choice}
            onClick={() => handleAnswerClick(choice)}
            highlight={choice === highlightedChoice}
          />
        ))}
      </div>
      <audio ref={rightSoundRef} src="/right.mp3"></audio>
      <audio ref={wrongSoundRef} src="/wrong.mp3"></audio>
    </div>
  );
};

export default ConsonantsQuiz;